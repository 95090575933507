<template>
  <div>
    <Treinos v-if="showTreinos" />
  </div>
</template>

<script>
import Treinos from '@/components/Treinos/Treinos'
  export default {
    name:"Registration",
    components:{Treinos},
    data:()=>({showTreinos: true}),
    mounted(){
      if(this.$store.state.treinos.allTreinos.length) return 
      this.$store.state.axios.get('/workouts').then(({data})=>this.$store.commit('treinos/setAllTreinos', data))
    },
    watch:{
      '$route.params.id'(){
        this.showTreinos = false
        this.$nextTick(()=>this.showTreinos = true)
      }
    }
  }
</script>

<style scoped>

</style>