<template>
  <v-card>
    <v-card-title>
      <table>
        <tr>
          <td>
            <v-avatar size="80" @click="$store.commit('photoAluno/setDialog', true)">
              <v-img v-if="$store.state.student.data?.avatar"
                :src="$store.state.baseUrl + '/student/avatar/' +$store.state.student.data?.avatar"
              ></v-img>
              <v-icon v-else x-large>mdi-account</v-icon>
            </v-avatar>
          </td>
          <td>
            <span class="text-h5 pl-3">{{ name }}</span>
          </td>
          <td><Status :item="$store.state.student.data" /></td>
        </tr>
      </table>
    </v-card-title>
    <v-expansion-panels v-model="panelsSpand">
      <Data />
      <v-expansion-panel v-for="(item, i) of panels" :key="i">
        <v-expansion-panel-header color="pink darken-4" class="ma-1">
          <span class="text-h6">Treino {{ item }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <List :treino="item" />
          <Frente />
          <!-- {{ item.items.join(",") }} -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions>
      <v-spacer /><v-btn x-large fab @click="add"
        ><v-icon dark> mdi-plus </v-icon></v-btn
      >
    </v-card-actions>
  </v-card>
</template>
<script>
import Status from '@/components/Students/Status.vue'
import List from "./List.vue";
import Data from "../Student/Student.vue";
import Frente from './FichaTreino/Frente.vue';
export default {
  name: "Treinos",
  components: { List, Data, Status, Frente },
  data: () => ({
    panelsSpand: 0,
    panelsData: ["A", "B", "C", "D", "E", "F"],
    panels: [],
  }),
  computed: {
    name(){
      const split = this.$store.state.student?.data?.name?.split(' ') || ''
      const firstName = split.slice(0,1)
      const lastName = split.slice(-1)
      return firstName === lastName ? lastName : firstName + ' ' + lastName
    },
    treinos() {
      return this.$store.state.student.data.treinos;
    },
  },
  methods: {
    add() {
      
      this.$nextTick(()=>{

        if (!this.panelsData.length) return;
        this.panels.push(this.panelsData.shift());
        //this.panels = this.panels.sort()
        this.panelsSpand = this.panels.length;
      })
    },
    changePanels(){
      if(!this.treinos) return this.panels = []
      Object.keys(this.treinos).forEach((key) => {
        this.panelsData.splice(this.panelsData.indexOf(key), 1);
        this.panels.push(key);
      });
      this.panels = this.panels.sort()
    }
  },
  mounted(){
    
    this.changePanels()
  },
  watch: {
    treinos() {
      this.changePanels()
    },
  },
};
</script>

<style lang="scss" scoped>
</style>