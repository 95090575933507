<template>
  <table border="1" width="100%" v-if="false">
    <tr>
      <th colspan="5">QUADRÍCEPS E ISQUIOTIBIAIS</th>
    </tr>
    <tr>
      <th>ordem</th>
      <th>exercícios</th>
      <th>série</th>
      <th>repet</th>
      <th>carga</th>
    </tr>
    <tr>
      <th></th>
      <th>AGACHAMENTOS</th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
    <tr v-for="(item, index) of agachamentos" :key="index" :agachamento="item">
      <td></td>
      <td class='d-flex justify-center'><v-checkbox
              v-model="item.active"
              :label="item.name"
              color="orange"
              :value="item.active"
              hide-details
              dense
            ></v-checkbox></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </table>
</template>

<script>
export default {
  data() {
    return {
      agachamentos: [{ name: "SUMÔ", active: true }],
    };
  },

};
</script>
