<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    class="elevation-1"
    disable-pagination
    hide-default-footer
    no-data-text=""
    dense
    mobile-breakpoint="400"
  >
    <template #item.gif="{ item }">
      <v-img :src="item.gif" width="50" @click="showGif(item.gif)" />
    </template>
    <template #item.actions="{ item }">
      <table>
        <tr>
          <td class="pa-2">
            <v-icon @click="deleteItem(item)"> mdi-delete-outline </v-icon>
          </td>
        </tr>
      </table>
    </template>

    <template #item.series="{ item }">
      <v-text-field v-model="item.series" @change="updateSeries(item)"></v-text-field>
    </template>

    <template #top>
      <table  class="pa-2">
        <tr>
          <td width="80%">
            <v-autocomplete
              v-model="treinoSelected"
              outlined
              hide-details
              hide-no-data
              label="Treinos"
              dense
              :items="$store.state.treinos.allTreinos"
              item-text="name"
              item-value="_id"
              color="green"
              filled
              flat
              rounded
              single-line
              success
              
            />
          </td>
          <td>
            <v-text-field v-model="series" success outlined rounded hide-details dense filled label="Séries"></v-text-field>
          </td>
          <td>
            <v-btn color="green" rounded @click="addItem" :loading="loading">ok</v-btn>
          </td>
          
        </tr>
      </table>
    </template>
  </v-data-table>
</template>
<script>
export default {
  props:['treino'],
  data() {
    return {
      loading: false,
      series: '',
      treinoSelected: '',
      headers: [
        {
          text: "Treino",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Modelo", value: "gif", sortable: false },
        { text: "Séries", value: "series", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
      desserts: [
        // {
        //   name: "Nome do Treino",
        //   series: '4 de 12',
        //   gif: "https://i.pinimg.com/originals/a7/1f/ff/a71fff79786764f33b5336a595e7a8da.gif",
        // },
      ],
    };
  },
  methods: {
    async updateSeries(item){
      try {
        await this.$store.state.axios.put('/student/treino/update', {_id: this.$store.state.student.data._id, treinoId: this.treino, treino: item })
        this.$store.dispatch('alerts/success', 'Treino alterado com sucesso')
      } catch (error) {
        this.$store.dispatch('alerts/error', error.response? error.response.data.error : error.stack)
      }
    },
    async addItem(){
      if(!this.treinoSelected) return this.$store.dispatch('alerts/error', 'Por favor, selecione um treino!')
      this.loading = true
      try {
        const treino = {...this.$store.state.treinos.allTreinos.find(item => item._id == this.treinoSelected), series: this.series}
        await this.$store.state.axios.post('/student/treino', {_id: this.$store.state.student.data._id, treino: {[this.treino]: treino}})
        this.desserts.push({...this.$store.state.treinos.allTreinos.find(item => item._id == this.treinoSelected), series: this.series})
        this.$store.dispatch('alerts/success', 'Treino adicionado com sucesso') 
      } catch (error) {
        this.$store.dispatch('alerts/error', error.response? error.response.data.error : error.stack)
      }
      this.$nextTick(()=>this.treinoSelected = null)
      this.loading = false
    },
    async deleteItem(item) {
      const data = {userId: this.$store.state.student.data._id, treino: this.treino, treinoId:item._id}
      this.$store.commit('setLoading', true)
      try {
        await this.$store.state.axios.put('/student/treino', data)
        this.$store.dispatch('alerts/success', 'Treino removido com sucesso')
        this.desserts.splice(this.desserts.indexOf(item), 1)
      } catch (error) {
        this.$store.dispatch('alerts/error', error.response ? error.response.data.error : error.stack)
      }
      this.$store.commit('setLoading', false)
      
    },
    showGif(gif) {
      this.$store.commit("modals/setUrlGif", gif);
      this.$store.commit("modals/setShowGif", true);
    },
  },
  mounted(){
    this.desserts = (this.$store.state.student.data.treinos || {})[this.treino] || []
  },

};
</script>