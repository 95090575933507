<template>
  <v-expansion-panel>
    <v-expansion-panel-header color="purple darken-4" class="ma-1">
      <span class="text-h6">Dados cadastrais</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-container fluid class="pa-0">
        <v-row no-gutters>
          <v-col>
            <table style="min-width: 250px">
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Nome completo"
                    dense
                    v-model="$store.state.student.data.name"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Telefone"
                    dense
                    v-model="$store.state.student.data.phone"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Email"
                    dense
                    v-model="$store.state.student.data.email"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="CPF"
                    dense
                    v-model="$store.state.student.data.cpf"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="CEP"
                    dense
                    maxLength="9"
                    @change="getCep"
                    v-model="$store.state.student.data.postalCode"
                  ></v-text-field>
                </td>
              </tr>
             
              <tr style="height: 50px">

                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Endereço"
                    dense
                    readonly
                    v-model="$store.state.student.data.address"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Número"
                    dense
                    v-model="$store.state.student.data.number"
                  ></v-text-field>
                </td>
              </tr>
              
              
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Complemento"
                    dense
                    v-model="$store.state.student.data.complement"
                  ></v-text-field>
                </td>
              </tr>
            </table>
          </v-col>
          <v-col>
            <table style="min-width: 250px">
              
              
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Bairro"
                    dense
                    readonly
                    v-model="$store.state.student.data.district"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Cidade"
                    dense
                    readonly
                    v-model="$store.state.student.data.city"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Estado"
                    dense
                    readonly
                    v-model="$store.state.student.data.state"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Data de início"
                    dense
                    v-model="startDate"
                    type="date"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Data do último pagamento"
                    dense
                    v-model="paymentDate"
                    type="date"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Data de nascimento"
                    dense
                    v-model="birth"
                    type="date"
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Idade"
                    dense
                    v-model="age"
                    readonly
                  ></v-text-field>
                </td>
              </tr>
              <tr style="height: 50px">
                <td>
                  <v-text-field
                    outlined
                    hide-details
                    label="Status do pagamento"
                    dense
                    v-model="$store.state.student.data.paymentStatus"
                  ></v-text-field>
                </td>
              </tr>

              <tr style="height: 50px">
                <td>
                  <v-btn color="green" @click="save" :loading="loadingSave"
                    >Salvar</v-btn
                  >
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              hide-details
              label="Obs"
              outlined
              v-model="$store.state.student.data.obs"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-expansion-panel-content>
    <Alerts
      :text="alertMessage"
      :type="alertType"
      v-if="alertMessage"
      @close="alertMessage = false"
    />
  </v-expansion-panel>
</template>

<script>
import Alerts from "../Snackbar/Snackbar.vue";
export default {
  components: { Alerts },
  data: () => ({
    staticStudent: {},
    loadingSave: false,
    alertMessage: "",
    alertType: "",
    birth: new Date(0).toLocaleDateString().split("/").reverse().join("-"),
  }),
  methods: {
    async getCep(value){
      const {data} = await this.$store.state.axios.get('/get-cep/'+value)
      
      this.$store.state.student.data.address = data.logradouroDNEC || data.logradouroTexto || 'CEP NÃO ENCONTRADO'
      this.$store.state.student.data.district = data.bairro || 'CEP NÃO ENCONTRADO'
      this.$store.state.student.data.city = data.localidade || 'CEP NÃO ENCONTRADO'
      this.$store.state.student.data.state = data.uf || 'CEP NÃO ENCONTRADO'
    },
    showAlert(message, type) {
      this.alertMessage = message;
      this.alertType = type;
      setTimeout(() => (this.alertMessage = false), 5000);
    },
    async save() {
      try {
        this.loadingSave = true;
        if (
          JSON.stringify(this.staticStudent) !==
          JSON.stringify(this.$store.state.student.data)
        ) {
          await this.$store.state.axios.post(
            "/student/save",
            this.$store.state.student.data
          );
          this.showAlert("Dados salvo com sucesso", "success");
          this.staticStudent = { ...this.$store.state.student.data };
        }
        this.loadingSave = false;
      } catch (error) {
        this.loadingSave = false;
        this.showAlert(
          "Dados não salvo - " +
            (error.response ? error.response.data.error : error.stack),
          "error"
        );
      }
    },
    getStudentById() {
      if (this.$store.state.student.data.name) return;
      this.$store.state.axios
        .get("/student/" + this.$route.params.id)
        .then(({ data }) => {
          if(!data) return this.$router.push('/')
          this.staticStudent = { ...data };
          this.$store.commit("student/setData", data);
          this.birth = new Date(data.birth || 0)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-");
        });
    },
  },
  computed: {
    startDate: {
      get() {
        return new Date(this.$store.state.student.data.startDate || 0)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-");
      },
      set(v) {
        this.$store.state.student.data.startDate = new Date(v + 'T03:00:00.000Z');
      },
    },
    paymentDate: {
      get() {
        return new Date(this.$store.state.student.data.paymentDate || 0)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-");
      },
      set(v) {
        this.$store.state.student.data.paymentDate = new Date(v + 'T03:00:00.000Z')
      },
    },
    age: {
      get() {
        const today = new Date();
        const birthDate = new Date(this.birth || 0);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
    },
    // birth: {
    //   get() {
    //     return new Date(this.$store.state.student.data.birth || 0)
    //       .toLocaleDateString()
    //       .split("/")
    //       .reverse()
    //       .join("-");
    //   },
    //   set(v) {
    //     this.$store.state.student.data.birth = v;
    //   },
    // },
  },

  beforeMount() {
    this.getStudentById();
  },
  mounted() {
    if(!this.$store.state.student.data.name) return;
    this.staticStudent = { ...this.$store.state.student.data };
    this.birth = new Date(this.$store.state.student.data.birth || 0)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-");
  },
  watch: {
    birth(v) {
      this.$store.state.student.data.birth = v;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>